//import './styles.css'

function Blog() {
    return (
        <>
            <blogTitleContainer>
                <div>
                    Blog O meu Salão
                    Acompanhe o nosso blog e fique por dentro dos insights.
                </div>
            </blogTitleContainer>
        </>
    );
}

export default Blog;