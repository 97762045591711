import './styles.css'

function Franchise() {
    return (
        <div className='franchisetitlecontainer'>
            <h1>Seja um franqueado O meu Salão</h1>
        </div>
    );
}

export default Franchise;