export const MenuData = [
    {
        title: "O INVISIBLE",
        url:"/Invisible",
        cName:"nav-links",
    },
    {
        title: "SERVIÇOS",
        url:"/Services",
        cName:"nav-links",
    },
    {
        title: "PRODUTOS",
        url:"/Products",
        cName:"nav-links",
    },
    {
        title: "CURSOS",
        url:"/Courses",
        cName:"nav-links",
    },
    {
        title: "SOBRE",
        url:"/About",
        cName:"nav-links",
    },
    {
        title: "FALE-CONOSCO",
        url:"/Contact",
        cName:"nav-links",
    },
]